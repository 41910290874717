import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const AboutMePage = () => (
  <Layout>
    <SEO title="About" />
    <div className="container page pt-16 pb-20 lg:max-w-lg body-content">
      <h1 className="hero-title mb-8 md:mb-12 lg:text-center">
        About Us
        <br />
        <span className="subtitle">“The Dream Shop” is a unique small business</span>
      </h1>
      <p>
        <b>I focus nearly exclusively on MLB jerseys.</b> I have a massive
        collection on site of over 1000 authentic and game worn jerseys, as well
        as a resource of almost one million photos of game worn jerseys to
        ensure I get it right every time.
      </p>
      <p>
        <b>I am first and foremost a historian.</b> Firstly and best known in
        the hobby as the author of the unabridged research volume “The Game Worn
        Guide to MLB Jerseys”, I have an intimate knowledge of all the jerseys
        worn by all of MLB Teams for the past many decades.
      </p>
      <p>
        <b>I am a trained and skilled graphic artist.</b> I have personally and
        painstakingly recreated nearly all the fonts used by every team for both
        player names and numbers back to 1970. While fonts used by one team are
        often “close” to those used by another, nearly every team has its own
        minute variations. I know the proper colors and sizes of this lettering,
        as well as the exact width of outlines. I know when nameplates are
        supposed to be used and when they are not, and in what arc the name
        should be correctly sewn. If you want your finished jersey to look
        exactly as it would have been worn on the field when new, I am your
        choice.
      </p>
      <p>
        <b>I have the proper skills, equipment and chemicals and tools</b> for
        restoring game worn jerseys, to cut lettering cleanly and exactly, to
        clean adhesive from removed lettering and remove many stains. I use a
        light table to determine exactly where original lettering was sewn so
        that I can craft and sew the recreated lettering exactly in the right
        places.
      </p>
      <p>
        <b>I have templates for everything</b>: When customizing new jerseys, I
        use my own historically recreated placement templates to sew names and
        numbers in the exact place that the teams sewed them. Player names are
        arched the same arc and style as the team originally did them.
      </p>
      <p>
        <b>
          I am skilled at recreating damaged or missing team scripts or
          wordmarks on the front of jerseys
        </b>
        . Whether these have been removed when sent to the minor leagues or
        faded and damaged so they do not present well, I am capable of making
        absolutely correct replacements.
      </p>
      <p>
        <b>
          I will always send you a computer-generated proof of how your jersey
          will look when done, for your approval, before I cut or sew anything
        </b>
        . This is a unique service that, to me, is common sense. My goal is to
        do it right the very first time. No rework, and no shipping jerseys back
        and forth to fix mistakes that should not have been made in the first
        place. I want to make all my clients happy.
      </p>
      <p>
        <b>
          I oversee all of this work myself, from design through sewing and
          shipping
        </b>
        . I am not a mass-production shop. I specialize on MLB and don’t do team
        orders and don’t accept work I can’t do efficiently and correctly. My
        prices are reasonable and my capacity is limited. I don’t take on more
        work than I can handle. I’ll let you know what my turnaround time is
        when I receive your order, will communicate with you throughout the
        process. When you send jerseys to me, I don’t sit on them for six months.
        I always ship “insured” back to you.
      </p>
    </div>
  </Layout>
);

export default AboutMePage;
